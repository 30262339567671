export const createConfetti = () => {
  const colors = ['#ff00ff', '#00ffff', '#ffff00'];
  
  const createConfettiPiece = () => {
    const confetti = document.createElement('div');
    confetti.className = 'confetti';
    confetti.style.left = `${Math.random() * 100}vw`;
    confetti.style.animationDuration = `${Math.random() * 3 + 2}s`;
    confetti.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
    document.body.appendChild(confetti);

    setTimeout(() => {
      confetti.remove();
    }, 5000);
  };

  for (let i = 0; i < 100; i++) {
    setTimeout(createConfettiPiece, i * 50);
  }
};