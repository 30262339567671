// src/utils/normalize.js

import nlp from 'compromise';

/**
 * Normalize the answer by converting it to its lemma form, removing punctuation,
 * and converting it to lowercase.
 * @param {string} answer
 * @returns {string} - Normalized answer
 */
export const normalizeAnswer = (answer) => {
  const doc = nlp(answer.toLowerCase().trim());
  const lemma = doc.terms().out('lemma'); // Get lemmatized version of each term
  return lemma.replace(/[^a-z0-9\s]/gi, ''); // Remove punctuation
};

/**
 * Calculate the similarity between two answers
 * by comparing their normalized forms.
 * @param {string} answer1
 * @param {string} answer2
 * @returns {boolean} - Whether the answers are considered similar
 */
export const areAnswersSimilar = (answer1, answer2) => {
  const normalizedAnswer1 = normalizeAnswer(answer1);
  const normalizedAnswer2 = normalizeAnswer(answer2);

  // Use a simple similarity check with string distance
  return normalizedAnswer1 === normalizedAnswer2 || 
         normalizedAnswer1.includes(normalizedAnswer2) || 
         normalizedAnswer2.includes(normalizedAnswer1);
};
