// src/utils/dalle.js

/**
 * Generates a creative image of Mt. Rushmore with custom items placed humorously on top of the heads.
 *
 * @param {string[]} choices - An array of items to be placed on top of the Mt. Rushmore heads.
 * @returns {Promise<string|null>} The URL of the generated image or null if an error occurs.
 */
export const generateMtRushmoreImage = async (choices) => {
    try {
      const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
      if (!openaiApiKey) {
        throw new Error('OpenAI API key is missing. Please set the REACT_APP_OPENAI_API_KEY environment variable.');
      }

      const basePrompt = "Create a realistic image of Mount Rushmore where the presidents' heads are replaced with:";
      const itemsPrompt = choices.join(', ');
      const detailPrompt = "Make items large, vivid, and positioned on the necks. Blend with rock texture. Use photorealistic rendering and natural lighting. Clear blue sky backdrop.";

      const fullPrompt = `${basePrompt} ${itemsPrompt}. ${detailPrompt}`.trim();

      if (fullPrompt.length > 1000) {
        console.warn('Prompt exceeds 1000 characters. Truncating...');
      }

      const truncatedPrompt = fullPrompt.slice(0, 1000);

      const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openaiApiKey}`,
        },
        body: JSON.stringify({
          prompt: truncatedPrompt,
          n: 1,
          size: '1024x1024',  // This is the correct format
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data?.error?.message || 'Unknown error occurred while generating the image.';
        throw new Error(errorMessage);
      }

      if (data?.data?.length > 0 && data.data[0].url) {
        return data.data[0].url;
      } else {
        throw new Error('No image generated.');
      }
    } catch (error) {
      console.error('Error generating Mt. Rushmore image:', error.message || error);
      return null;
    }
  };
  