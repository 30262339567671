// src/utils/fetchGPTSuggestions.js

import axios from 'axios';

/**
 * Fetch suggestions using the OpenAI API.
 * 
 * @param {string} input - The user's input to base suggestions on.
 * @param {string} topic - The current topic to provide context for suggestions.
 * @returns {Promise<string[]>} - An array of suggestions.
 */
export const fetchGPTSuggestions = async (input, topic) => {
  // Exit early if input is too short, avoiding unnecessary API calls
  if (!input || input.length < 2) {
    return [];
  }

  try {
    // API request configuration
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant that provides relevant suggestions based on the current topic: "${topic}".`,
          },
          {
            role: 'user',
            content: `Given the topic "${topic}", suggest words or phrases that start with "${input}". Provide concise and relevant suggestions, without numbering.`,
          },
        ],
        max_tokens: 50, // Allow for a broader range of suggestions
        n: 1, // Single completion for efficient performance
        temperature: 0.5, // Moderate temperature for balance between randomness and relevance
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      }
    );

    // Extract and clean suggestions from the response
    const suggestions = response.data.choices[0].message.content
      .split('\n')
      .map((suggestion) => suggestion.replace(/^\d+\.\s*/, '').trim()) // Remove any numbering
      .filter((suggestion) => suggestion.length > 0);

    return suggestions.slice(0, 5); // Return the top 5 suggestions
  } catch (error) {
    console.error('Error fetching GPT suggestions:', error);
    return []; // Return an empty array on error
  }
};
