// src/utils/moderation.js

export const moderateAnswers = async (answers) => {
  try {
    const response = await fetch('https://api.openai.com/v1/moderations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Using your API key from .env
      },
      body: JSON.stringify({ input: answers }),
    });

    const data = await response.json();
    return data.results.every((result) => !result.flagged);
  } catch (error) {
    console.error('Moderation error:', error);
    return false;
  }
};
