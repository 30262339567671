// src/components/Footer.js

import React from 'react';
import { Box, Flex, Text, Link, Button } from '@chakra-ui/react';
import { initializeAccessibilityControls } from '../utils/accessibilityControls';

const Footer = () => {
  const { toggleHighContrast, toggleLargerText, toggleReducedMotion } = initializeAccessibilityControls();

  return (
    <Box
      bg="rgba(0, 255, 255, 0.1)"
      color="white"
      py={4}
      mt={8}
      borderTop="1px solid var(--color-primary)"
    >
      <Flex direction="column" align="center" maxW="1200px" mx="auto" px={6}>
        <Text
          fontSize="lg"
          fontWeight="bold"
          fontFamily="var(--font-display)"
          textAlign="center"
          color="var(--color-text)"
        >
          © 2024 Daily Rushmore. All rights reserved.
        </Text>
        <Flex mt={4} flexWrap="wrap" justify="center">
          <Link
            href="#"
            mx={2}
            my={1}
            color="white"
            _hover={{ color: 'var(--color-secondary)' }}
          >
            Privacy Policy
          </Link>
          <Link
            href="#"
            mx={2}
            my={1}
            color="white"
            _hover={{ color: 'var(--color-secondary)' }}
          >
            Terms of Service
          </Link>
          <Link
            href="#"
            mx={2}
            my={1}
            color="white"
            _hover={{ color: 'var(--color-secondary)' }}
          >
            Contact Us
          </Link>
        </Flex>
        <Flex
          mt={4}
          flexWrap="wrap"
          justify="center"
          className="accessibility-controls"
        >
          <Button
            onClick={toggleHighContrast}
            mx={2}
            my={1}
            size="sm"
            variant="outline"
            borderColor="var(--color-primary)"
            _hover={{
              bg: 'var(--color-primary)',
              color: 'var(--color-background)',
            }}
          >
            High Contrast
          </Button>
          <Button
            onClick={toggleLargerText}
            mx={2}
            my={1}
            size="sm"
            variant="outline"
            borderColor="var(--color-primary)"
            _hover={{
              bg: 'var(--color-primary)',
              color: 'var(--color-background)',
            }}
          >
            Larger Text
          </Button>
          <Button
            onClick={toggleReducedMotion}
            mx={2}
            my={1}
            size="sm"
            variant="outline"
            borderColor="var(--color-primary)"
            _hover={{
              bg: 'var(--color-primary)',
              color: 'var(--color-background)',
            }}
          >
            Reduced Motion
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
