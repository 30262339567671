export const initializeVoiceCommands = (onCommand) => {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  
  if (!SpeechRecognition) {
    console.warn('Speech recognition not supported');
    return null;
  }

  const recognition = new SpeechRecognition();
  recognition.continuous = false;
  recognition.lang = 'en-US';
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  recognition.onresult = (event) => {
    const command = event.results[0][0].transcript.toLowerCase();
    onCommand(command);
  };

  const startListening = () => {
    recognition.start();
  };

  return startListening;
};