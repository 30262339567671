export const initializeGestureControl = (onGesture) => {
  let startX, startY, endX, endY;
  const minSwipeDistance = 50;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
    startY = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (!startX || !startY) return;

    endX = e.touches[0].clientX;
    endY = e.touches[0].clientY;

    const diffX = startX - endX;
    const diffY = startY - endY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // Horizontal swipe
      if (Math.abs(diffX) > minSwipeDistance) {
        onGesture(diffX > 0 ? 'swipeLeft' : 'swipeRight');
      }
    } else {
      // Vertical swipe
      if (Math.abs(diffY) > minSwipeDistance) {
        onGesture(diffY > 0 ? 'swipeUp' : 'swipeDown');
      }
    }

    startX = null;
    startY = null;
  };

  document.addEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchmove', handleTouchMove, false);

  return () => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchmove', handleTouchMove);
  };
};