import React from 'react';
import { Box, Flex, Heading, Button, Link, useColorMode, IconButton } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box as="header" bg="rgba(0, 255, 255, 0.1)" color="white" py={4} backdropFilter="blur(10px)" borderBottom="1px solid var(--color-primary)">
      <Flex
        maxW="1200px"
        mx="auto"
        px={6}
        align="center"
        justify="space-between"
        direction={['column', 'row']}
      >
        <Heading as="h1" size="lg" fontWeight="bold" fontFamily="var(--font-display)" className="glitch-effect" data-text="Daily Rushmore">
          Daily Rushmore
        </Heading>
        
        <Flex mt={[4, 0]} align="center" flexWrap="wrap" justifyContent={['center', 'flex-end']}>
          {/* Navigation Links */}
          {['Home', 'About', 'How It Works'].map((item, index) => (
            <Link
              key={index}
              href="#"
              mx={2}
              my={1}
              fontWeight="medium"
              _hover={{ textDecoration: 'none', color: 'var(--color-secondary)' }}
              className="header__nav-link"
            >
              {item}
            </Link>
          ))}

          {/* Dark/Light Mode Toggle */}
          <IconButton
            aria-label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`}
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            mx={2}
            my={1}
            variant="ghost"
            color="var(--color-tertiary)"
            _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
          />

          {/* Sign In Button */}
          <Button
            ml={2}
            my={1}
            bg="var(--color-secondary)"
            color="var(--color-background)"
            className="ripple-button"
            _hover={{ bg: 'var(--color-tertiary)' }}
          >
            Sign In
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
