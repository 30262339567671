import React, { useEffect, useRef } from 'react';
import { ChakraProvider, Box, Container, Flex, extendTheme, CSSReset } from '@chakra-ui/react';
import HomePage from './components/HomePage';
import PreviousRushmores from './components/PreviousRushmores';
import Header from './components/Header';
import Footer from './components/Footer';
import { signInAnonymouslyUser } from './firebase';
import { createEasterEgg } from './utils/easterEgg';
import { initializeVoiceCommands } from './utils/voiceCommand';
import { initializeGestureControl } from './utils/gestureControl';
import { initializeAccessibilityControls } from './utils/accessibilityControls';
import { createConfetti } from './utils/confetti';

// Import the existing CSS file
import './App.css';

// Custom theme configuration for Chakra UI
const customTheme = extendTheme({
  fonts: {
    heading: "'Orbitron', sans-serif",
    body: "'Roboto', sans-serif",
  },
  colors: {
    primary: {
      500: '#00ffff',
    },
    secondary: {
      500: '#ff00ff',
    },
    tertiary: {
      500: '#ffff00',
    },
    background: '#0a0a0a',
    text: '#ffffff',
  },
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
});

function App() {
  const accessibilityRef = useRef(null);
  const voiceCommandCleanupRef = useRef(null);

  useEffect(() => {
    // Sign in anonymously
    signInAnonymouslyUser();

    // Initialize Easter Egg and Gesture Control
    const cleanupFunctions = [
      createEasterEgg(handleEasterEggFound),
      initializeGestureControl(handleGesture),
    ];

    // Initialize Voice Commands and save cleanup function
    voiceCommandCleanupRef.current = initializeVoiceCommands(handleVoiceCommand);

    // Initialize Accessibility Controls
    const accessibilityControls = initializeAccessibilityControls();

    // Check if the accessibility container already exists
    let accessibilityContainer = document.querySelector('.accessibility-controls');
    if (!accessibilityContainer) {
      // Create Accessibility Controls Container
      accessibilityContainer = document.createElement('div');
      accessibilityContainer.className = 'accessibility-controls';
      document.body.appendChild(accessibilityContainer);
    } else {
      // Clear existing buttons to avoid duplicates
      accessibilityContainer.innerHTML = '';
    }

    // Create Accessibility Button Function
    const createAccessibilityButton = (text, onClick) => {
      const button = document.createElement('button');
      button.textContent = text;
      button.className = 'accessibility-button';
      button.addEventListener('click', onClick);
      accessibilityContainer.appendChild(button);
    };

    // Create Accessibility Buttons
    createAccessibilityButton('High Contrast', accessibilityControls.toggleHighContrast);
    createAccessibilityButton('Larger Text', accessibilityControls.toggleLargerText);
    createAccessibilityButton('Reduced Motion', accessibilityControls.toggleReducedMotion);

    // Cleanup on component unmount
    return () => {
      // Cleanup Easter Egg and Gesture Control
      cleanupFunctions.forEach((fn) => fn && fn());

      // Cleanup Voice Commands
      if (voiceCommandCleanupRef.current) {
        voiceCommandCleanupRef.current();
      }

      // Safely remove Accessibility Controls if they exist
      if (accessibilityContainer && accessibilityContainer.parentNode) {
        accessibilityContainer.parentNode.removeChild(accessibilityContainer);
      }
    };
  }, []);

  // Handle Easter Egg Found
  const handleEasterEggFound = () => {
    console.log('Easter egg found!');
    createConfetti();
  };

  // Handle Voice Command
  const handleVoiceCommand = (command) => {
    console.log('Voice command:', command);
    // Handle specific voice commands
    if (command === 'show previous rushmores') {
      // Example action based on voice command
      // You might use a state to show/hide components
    }
    // Add more command handling as needed
  };

  // Handle Gesture
  const handleGesture = (gesture) => {
    console.log('Gesture detected:', gesture);
    // Handle specific gestures
    if (gesture === 'swipe right') {
      // Example action based on gesture
    }
    // Add more gesture handling as needed
  };

  return (
    <ChakraProvider theme={customTheme}>
      <CSSReset />
      <Box className="App" ref={accessibilityRef}>
        <Header />
        <Container maxW="container.xl" py={8}>
          <Flex direction="column" align="center" justify="center">
            <HomePage />
            <PreviousRushmores />
          </Flex>
        </Container>
        <Footer />
        <div className="particle-container">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="particle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`,
              }}
            ></div>
          ))}
        </div>
      </Box>
    </ChakraProvider>
  );
}

export default App;
