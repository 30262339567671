import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Image,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  Heading,
  Container,
  Button,
  SlideFade,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import MtRushmoreForm from './MtRushmoreForm';
import PopularAnswers from './PopularAnswers';
import { fetchDailyTopic, submitUserAnswers, calculatePopularAnswers } from '../firebaseFunctions';
import { moderateAnswers } from '../utils/moderation';
import { generateMtRushmoreImage } from '../utils/dalle';
import { createConfetti } from '../utils/confetti';

const HomePage = () => {
  const [popularAnswers, setPopularAnswers] = useState([]);
  const [topic, setTopic] = useState('');
  const [imageURL, setImageURL] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingTopic, setLoadingTopic] = useState(true);
  const [error, setError] = useState(null);
  const topicDate = new Date().toISOString().split('T')[0];
  const toast = useToast();

  useEffect(() => {
    const initializeTopic = async () => {
      try {
        const dailyTopic = await fetchDailyTopic();
        if (dailyTopic) {
          setTopic(dailyTopic.name);
        }
        const answers = await calculatePopularAnswers(topicDate);
        setPopularAnswers(answers || []);
      } catch (error) {
        console.error('Error initializing topic:', error);
        setError('Failed to load daily topic. Please try again later.');
      } finally {
        setLoadingTopic(false);
      }
    };

    initializeTopic();
  }, [topicDate]);

  const handleSubmit = async (answers) => {
    const isValid = await moderateAnswers(answers);
    if (!isValid) {
      toast({
        title: 'Invalid Content',
        description: 'Your answers contain inappropriate content. Please revise.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setError(null);
      await submitUserAnswers('user-123', answers, topicDate);
      setLoadingImage(true);
      const url = await generateMtRushmoreImage(answers);
      if (url) {
        setImageURL(url);
        createConfetti();
        toast({
          title: 'Rushmore Created',
          description: 'Your Mt. Rushmore image has been generated successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Image generation failed');
      }
    } catch (error) {
      console.error('Error processing answers:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while processing your request. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingImage(false);
    }
  };

  const handleShare = () => {
    toast({
      title: 'Share Feature',
      description: 'Sharing functionality is coming soon!',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.xl" className="main-content">
      {loadingTopic ? (
        <Box className="flex-center-center" height="50vh">
          <Spinner size="xl" color="var(--color-primary)" />
        </Box>
      ) : (
        <SlideFade in={!loadingTopic} offsetY="20px">
          {error && (
            <Alert status="error" mb={4} borderRadius="md" bg="rgba(255, 0, 0, 0.1)" color="var(--color-text)">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <Box className="topic-card">
            <Heading as="h1" className="topic-card__title">
              Today's Topic:
            </Heading>
            <Text className="topic-card__description">
              {topic}
            </Text>
          </Box>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} mt={6}>
            <VStack spacing={6} align="stretch">
              <Box className="game-card">
                <MtRushmoreForm onSubmit={handleSubmit} topic={topic} />
              </Box>
              <PopularAnswers popularAnswers={popularAnswers} />
            </VStack>
            <VStack spacing={5} align="center" justify="center">
              {loadingImage ? (
                <Box className="game-card flex-center-center" height="400px">
                  <VStack>
                    <Spinner size="xl" color="var(--color-primary)" />
                    <Text fontSize="lg" color="var(--color-text)">Generating your Mt. Rushmore image...</Text>
                  </VStack>
                </Box>
              ) : (
                imageURL && (
                  <Box className="game-card rushmore-preview">
                    <Heading as="h2" size="lg" mb={3} className="game-card__title">
                      Your Personalized Mt. Rushmore
                    </Heading>
                    <Image src={imageURL} alt="Mt. Rushmore Image" className="rushmore-preview__image" />
                  </Box>
                )
              )}
            </VStack>
          </SimpleGrid>
          <Button 
            mt={6} 
            bg="var(--color-secondary)"
            color="var(--color-background)"
            size="lg" 
            className="ripple-button"
            onClick={handleShare}
            isDisabled={!imageURL}
            _hover={{ bg: 'var(--color-primary)' }}
          >
            Share Your Rushmore
          </Button>
        </SlideFade>
      )}
    </Container>
  );
};

export default HomePage;
