export const initializeAccessibilityControls = () => {
  const toggleHighContrast = () => {
    document.body.classList.toggle('high-contrast');
  };

  const toggleLargerText = () => {
    document.body.classList.toggle('larger-text');
  };

  const toggleReducedMotion = () => {
    document.body.classList.toggle('reduced-motion');
  };

  return {
    toggleHighContrast,
    toggleLargerText,
    toggleReducedMotion
  };
};