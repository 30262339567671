// src/firebaseFunctions.js

import {
    collection,
    getDocs,
    query,
    where,
    doc,
    getDoc,
    setDoc,
    addDoc,
    writeBatch,
    Timestamp,
  } from 'firebase/firestore';
  import { db } from './firebase';
  import { normalizeAnswer } from './utils/normalize';
  
  /**
   * Fetches the current daily topic from Firestore.
   * @returns {Promise<Object|null>} The daily topic data or null if not found.
   */
  export const fetchDailyTopic = async () => {
    try {
      const topicRef = doc(db, 'dailyTopic', 'current');
      const topicSnapshot = await getDoc(topicRef);
  
      if (topicSnapshot.exists()) {
        const topicData = topicSnapshot.data();
        const today = new Date().toISOString().split('T')[0];
  
        if (topicData.date !== today) {
          // Save the old topic and create a new one if the date doesn't match
          await savePreviousTopic(topicData);
          const newTopic = await createNewDailyTopic();
          return newTopic;
        }
        return topicData;
      } else {
        console.warn('No daily topic found');
        return await createNewDailyTopic(); // Create a new topic if none exists
      }
    } catch (error) {
      console.error('Error fetching daily topic:', error);
      return null;
    }
  };
  
  /**
   * Saves the previous topic to the previousTopics collection.
   * @param {Object} topicData - The topic data to be saved.
   */
  const savePreviousTopic = async (topicData) => {
    try {
      const previousTopicsRef = collection(db, 'previousTopics');
      await addDoc(previousTopicsRef, {
        ...topicData,
        timestamp: Timestamp.now(),
      });
      console.log('Previous topic saved successfully');
    } catch (error) {
      console.error('Error saving previous topic:', error);
    }
  };
  
  /**
   * Creates a new daily topic.
   * @returns {Promise<Object>} The newly created topic data.
   */
  const createNewDailyTopic = async () => {
    const today = new Date().toISOString().split('T')[0];
    const selectedTopic = selectRandomTopic(); // Function to select a random topic
  
    const newTopic = {
      name: selectedTopic,
      date: today,
    };
  
    try {
      const topicRef = doc(db, 'dailyTopic', 'current');
      await setDoc(topicRef, newTopic);
      console.log(`New daily topic created: ${selectedTopic}`);
      return newTopic;
    } catch (error) {
      console.error('Error creating new daily topic:', error);
      return null;
    }
  };
  
  /**
   * Submits user answers to Firestore for a given topic date.
   * @param {string} userId - The ID of the user submitting the answers.
   * @param {string[]} answers - The array of answers provided by the user.
   * @param {string} topicDate - The date of the topic for which answers are submitted.
   */
  export const submitUserAnswers = async (userId, answers, topicDate) => {
    try {
      const userAnswersRef = collection(db, 'userAnswers');
      const batch = writeBatch(db);
  
      answers.forEach((answer) => {
        const docRef = doc(userAnswersRef); // Create a new document reference for each answer
        batch.set(docRef, {
          answer: normalizeAnswer(answer),
          userId,
          date: topicDate,
          timestamp: Timestamp.now(),
        });
      });
  
      await batch.commit(); // Commit the batch operation
      console.log('User answers submitted successfully');
    } catch (error) {
      console.error('Error submitting user answers:', error);
    }
  };
  
  /**
   * Calculates the most popular answers for a given topic date.
   * @param {string} topicDate - The date of the topic for which to calculate popular answers.
   * @returns {Promise<Object[]>} An array of objects representing the top 4 popular answers with their counts.
   */
  export const calculatePopularAnswers = async (topicDate) => {
    try {
      const userAnswersRef = collection(db, 'userAnswers');
      const q = query(userAnswersRef, where('date', '==', topicDate));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        console.warn(`No answers found for date: ${topicDate}`);
        return [];
      }
  
      const answerCount = {};
  
      querySnapshot.forEach((doc) => {
        const { answer } = doc.data();
        if (answerCount[answer]) {
          answerCount[answer] += 1;
        } else {
          answerCount[answer] = 1;
        }
      });
  
      const sortedAnswers = Object.entries(answerCount).sort((a, b) => b[1] - a[1]);
      const topAnswers = sortedAnswers.slice(0, 4).map(([answer, count]) => ({ answer, count }));
  
      // Save popular answers to Firestore
      await savePopularAnswers(topicDate, topAnswers);
  
      console.log(`Top answers for ${topicDate}:`, topAnswers);
      return topAnswers;
    } catch (error) {
      console.error('Error calculating popular answers:', error);
      return [];
    }
  };
  
  /**
   * Saves the popular answers to Firestore.
   * @param {string} topicDate - The date of the topic.
   * @param {Object[]} popularAnswers - The array of popular answers to save.
   */
  const savePopularAnswers = async (topicDate, popularAnswers) => {
    try {
      const popularAnswersRef = doc(db, 'popularAnswers', topicDate);
      await setDoc(popularAnswersRef, { answers: popularAnswers, date: topicDate });
      console.log(`Popular answers saved for ${topicDate}`);
    } catch (error) {
      console.error('Error saving popular answers:', error);
    }
  };
  
  /**
   * Fetches previous topics from Firestore.
   * @returns {Promise<Object[]>} An array of previous topic objects.
   */
  export const fetchPreviousTopics = async () => {
    try {
      const previousTopicsRef = collection(db, 'previousTopics');
      const q = query(previousTopicsRef, where('date', '<', new Date().toISOString().split('T')[0]));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        console.warn('No previous topics found');
        return [];
      }
  
      const topics = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      console.log('Fetched previous topics successfully:', topics);
      return topics;
    } catch (error) {
      console.error('Error fetching previous topics:', error);
      return [];
    }
  };
  
  /**
   * Utility function to select a random topic from a predefined list.
   * You can expand this list as needed.
   */
  const selectRandomTopic = () => {
    const topics = [
      'Best Pizza Toppings',
      'Favorite Fast Food Chains',
      'Top Comedy Movies',
      'Dream Travel Destinations',
      'Most Inspiring Books',
      // Add more topics as needed
    ];
  
    const randomIndex = Math.floor(Math.random() * topics.length);
    return topics[randomIndex];
  };
  