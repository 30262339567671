// src/components/PopularAnswers.js

import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Progress, Badge, Spinner } from '@chakra-ui/react'; // Import Spinner for loading state
import { db } from '../firebase'; // Ensure this is correctly set up
import { collection, query, where, getDocs } from 'firebase/firestore';

const PopularAnswers = ({ date }) => {
  const [popularAnswers, setPopularAnswers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPopularAnswers = async () => {
      setLoading(true); // Set loading to true at the start
      try {
        // Reference the 'popularAnswers' collection in Firestore
        const popularAnswersRef = collection(db, 'popularAnswers');
        
        // Create a query to find documents with the matching date
        const q = query(popularAnswersRef, where('date', '==', date));

        // Execute the query and get a snapshot of the results
        const querySnapshot = await getDocs(q);

        // Extract document data and store it in the answers array
        const answers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort answers by count in descending order
        setPopularAnswers(answers.sort((a, b) => b.count - a.count));
      } catch (error) {
        console.error('Error fetching popular answers:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    // Fetch answers only if the date is valid
    if (date) {
      fetchPopularAnswers();
    }
  }, [date]); // Re-run the effect when the date changes

  return (
    <VStack spacing={4} mt={8} align="stretch" className="popular-answers-container">
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" className="popular-answers-title">
        Today's Popular Answers:
      </Text>

      {loading ? (
        <Spinner size="lg" color="var(--color-primary)" /> // Spinner to indicate loading
      ) : popularAnswers.length > 0 ? (
        popularAnswers.map((answer, index) => (
          <Box
            key={answer.id} // Use a unique key from Firestore
            p={4}
            borderWidth={2}
            borderRadius="lg"
            boxShadow="md"
            className="popular-answer-box"
            _hover={{ boxShadow: 'lg' }}
            bg={index === 0 ? "yellow.100" : "gray.50"} // Highlight the leader
          >
            <Text fontSize="lg" fontWeight="semibold" className="answer-text">
              {answer.answer}
              <Badge ml={2} colorScheme={index === 0 ? "yellow" : "gray"} className="answer-badge">
                {answer.count} votes
              </Badge>
            </Text>
            <Progress
              mt={2}
              value={(answer.count / popularAnswers[0].count) * 100}
              colorScheme={index === 0 ? "yellow" : "teal"}
              className="answer-progress"
            />
          </Box>
        ))
      ) : (
        <Text className="no-popular-answers" textAlign="center" color="gray.500">
          No popular answers available yet.
        </Text>
      )}
    </VStack>
  );
};

export default PopularAnswers;
