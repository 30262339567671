// src/components/MtRushmoreForm.js

import React, { useState, useCallback } from 'react';
import { Input, Button, VStack, Box, List, ListItem, Spinner } from '@chakra-ui/react';
import { fetchGPTSuggestions } from '../utils/fetchGPTSuggestions';
import debounce from 'lodash.debounce';

const MtRushmoreForm = ({ onSubmit, topic }) => {
  const [answers, setAnswers] = useState(['', '', '', '']);
  const [suggestions, setSuggestions] = useState([[], [], [], []]);
  const [loadingSuggestions, setLoadingSuggestions] = useState([false, false, false, false]);
  const [showSuggestions, setShowSuggestions] = useState([false, false, false, false]);

  const fetchSuggestionsDebounced = useCallback((index, value) => {
    const debouncedFetch = debounce(async () => {
      if (value.length < 2) {
        setShowSuggestions(prev => {
          const newShow = [...prev];
          newShow[index] = false;
          return newShow;
        });
        return;
      }
  
      setLoadingSuggestions(prev => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
  
      try {
        const fetchedSuggestions = await fetchGPTSuggestions(value, topic);
        setSuggestions(prev => {
          const newSuggestions = [...prev];
          newSuggestions[index] = fetchedSuggestions;
          return newSuggestions;
        });
  
        setShowSuggestions(prev => {
          const newShow = [...prev];
          newShow[index] = true;
          return newShow;
        });
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setLoadingSuggestions(prev => {
          const newLoading = [...prev];
          newLoading[index] = false;
          return newLoading;
        });
      }
    }, 300);
  
    debouncedFetch();
  }, [topic]); // Removed fetchGPTSuggestions from the dependency array

  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
    fetchSuggestionsDebounced(index, value);
  };

  const handleSuggestionClick = (index, suggestion) => {
    const newAnswers = [...answers];
    newAnswers[index] = suggestion;
    setAnswers(newAnswers);

    setShowSuggestions((prev) => {
      const newShow = [...prev];
      newShow[index] = false;
      return newShow;
    });
  };

  const handleSubmit = () => {
    onSubmit(answers);
  };

  return (
    <VStack spacing={4} align="stretch" className="rushmore-form">
      {answers.map((answer, index) => (
        <Box key={index} position="relative" className="rushmore-form__input-group">
          <Input
            placeholder={`Choice ${index + 1}`}
            value={answer}
            onChange={(e) => handleChange(index, e.target.value)}
            onBlur={() =>
              setShowSuggestions((prev) => {
                const newShow = [...prev];
                newShow[index] = false;
                return newShow;
              })
            }
            onFocus={() =>
              setShowSuggestions((prev) => {
                const newShow = [...prev];
                newShow[index] = true;
                return newShow;
              })
            }
            autoComplete="off"
            variant="filled"
            size="md"
            className="rushmore-form__input"
          />
          {loadingSuggestions[index] && (
            <Box position="absolute" right="10px" top="50%" transform="translateY(-50%)">
              <Spinner size="sm" color="teal.500" />
            </Box>
          )}
          {showSuggestions[index] && suggestions[index].length > 0 && (
            <List
              spacing={1}
              mt={1}
              borderWidth={1}
              borderRadius="md"
              bg="white"
              boxShadow="lg"
              maxH="200px"
              overflowY="auto"
              position="absolute"
              width="100%"
              zIndex="10"
              className="suggestion-list"
            >
              {suggestions[index].map((suggestion, suggestionIndex) => (
                <ListItem
                  key={suggestionIndex}
                  p={2}
                  cursor="pointer"
                  _hover={{ bg: 'gray.100', fontWeight: 'bold' }}
                  onClick={() => handleSuggestionClick(index, suggestion)}
                  className="suggestion-list__item"
                >
                  {suggestion}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      ))}
      <Button colorScheme="teal" onClick={handleSubmit} className="rushmore-form__submit" mt={4}>
        Finalize My Mt. Rushmore
      </Button>
    </VStack>
  );
};

export default MtRushmoreForm;