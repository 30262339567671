export const createEasterEgg = (onEasterEggFound) => {
  const easterEgg = document.createElement('div');
  easterEgg.classList.add('easter-egg');
  easterEgg.innerHTML = '🥚';
  easterEgg.style.cssText = `
    position: fixed;
    top: ${Math.random() * window.innerHeight}px;
    left: ${Math.random() * window.innerWidth}px;
    font-size: 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  `;
  document.body.appendChild(easterEgg);

  let clickCount = 0;
  easterEgg.addEventListener('click', () => {
    clickCount++;
    if (clickCount === 5) {
      onEasterEggFound();
    }
  });

  return () => {
    document.body.removeChild(easterEgg);
  };
};