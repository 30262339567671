// src/components/PreviousRushmores.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Button,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  SlideFade,
} from '@chakra-ui/react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const PreviousRushmores = () => {
  const [previousTopics, setPreviousTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPreviousTopics = async () => {
      setLoading(true);
      try {
        const topicsSnapshot = await getDocs(collection(db, 'previousTopics'));
        const topicsData = topicsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const sortedTopics = topicsData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPreviousTopics(sortedTopics);
      } catch (error) {
        console.error('Error fetching previous topics: ', error);
        setError('Failed to load previous topics. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchPreviousTopics();
  }, []);

  const handleViewTopic = (topicId) => {
    console.log('Viewing topic with ID:', topicId);
    // Implement navigation to a detailed view page or modal
  };

  return (
    <VStack spacing={5} mt={8} align="stretch" className="previous-rushmores">
      <Text
        fontSize="3xl"
        fontWeight="bold"
        textAlign="center"
        color="var(--color-primary)"
        className="previous-title"
      >
        Previous Rushmores
      </Text>
      {loading ? (
        <Box className="flex-center-center" height="200px">
          <Spinner size="xl" color="var(--color-primary)" />
        </Box>
      ) : error ? (
        <Alert
          status="error"
          borderRadius="md"
          bg="rgba(255, 0, 0, 0.1)"
          color="var(--color-text)"
        >
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <SlideFade in={!loading} offsetY="20px">
          {previousTopics.length > 0 ? (
            previousTopics.map((topic) => (
              <Box
                key={topic.id}
                className="game-card topic-box"
                _hover={{
                  transform: 'translateY(-5px)',
                  boxShadow: '0 5px 15px rgba(0, 255, 255, 0.3)',
                }}
              >
                <Text
                  fontSize="lg"
                  fontWeight="medium"
                  className="topic-text"
                  color="var(--color-text)"
                >
                  {topic.date}: {topic.name}
                </Text>
                <Button
                  mt={2}
                  onClick={() => handleViewTopic(topic.id)}
                  className="view-button ripple-button"
                  size="sm"
                  bg="var(--color-secondary)"
                  color="var(--color-background)"
                  _hover={{ bg: 'var(--color-primary)' }}
                >
                  View
                </Button>
              </Box>
            ))
          ) : (
            <Text
              fontSize="lg"
              textAlign="center"
              color="var(--color-text)"
              className="hologram-effect"
            >
              No previous topics available.
            </Text>
          )}
        </SlideFade>
      )}
    </VStack>
  );
};

export default PreviousRushmores;
