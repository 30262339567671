import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react'; // Import ChakraProvider
import './index.css'; // Import global styles
import App from './App';
import reportWebVitals from './reportWebVitals';

// Create root for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ChakraProvider> {/* Wrap the app with ChakraProvider if using Chakra UI */}
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// Enable performance measurements
reportWebVitals(console.log); // Use this to log performance or send it to an endpoint
